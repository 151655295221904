import React, { useEffect, useState } from "react";
import PaidIcon from "@mui/icons-material/Paid";
import { Paper, Button } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { json, Link } from "react-router-dom";
import { Box } from "@material-ui/core";

import LinearProgress from "@mui/material/LinearProgress";

import PedidosProceso from "../../assets/PedidosProceso.svg";
import PedidosHoy from "../../assets/PedidosHoy.svg";
import Pedidos7dias from "../../assets/Pedidos7dias.svg";
import TotalPedidos from "../../assets/TotalPedidos.svg";
import { getDataDash } from "./funciones/dashboard";
import ChartDonut from "./Charts/ChartDonut";
import ChartTop from "./Charts/ChartTop";
import ChartLine from "./Charts/ChartLine";
import ChartBar from "./Charts/ChartBar";

const fechaActual = new Date();
const anioActual = fechaActual.getFullYear();
function obtenerNombreDia(dia, mes) {
  const fecha = new Date();
  fecha.setDate(dia);
  fecha.setMonth(mes - 1);
  const nombreDia = fecha.toLocaleDateString("es-ES", { weekday: "long" });
  return nombreDia.charAt(0).toUpperCase() + nombreDia.slice(1);
}
function numberToMes(mes, anio = "") {
  let an = "";
  if (anio != anioActual) {
    //an = ` ${anio}`;
  }
  switch (mes) {
    case 1:
      return "Enero" + an;
    case 2:
      return "Febrero" + an;
    case 3:
      return "Marzo" + an;
    case 4:
      return "Abril" + an;
    case 5:
      return "Mayo" + an;
    case 6:
      return "Junio" + an;
    case 7:
      return "Julio" + an;
    case 8:
      return "Agosto" + an;
    case 9:
      return "Septiembre" + an;
    case 10:
      return "Octubre" + an;
    case 11:
      return "Noviembre" + an;
    case 12:
      return "Diciembre" + an;

    default:
      return "N/A";
  }
}

function dataTop(img, titulos, subtitulos) {
  return { img, titulos, subtitulos };
}
function dataTopPros(img, titulos, subtitulos, puntos) {
  return { img, titulos, subtitulos, puntos };
}

export default function DashboardAdmin() {
  const [IsLoading, setIsLoading] = useState(true);
  const [graficas, setGraficas] = useState([]);
  const [cargado, setCargado] = useState(false);
  const tamanioIcons = 55;
  const h = "400px";

  useEffect(() => {
    document.title = "Dashboard";
    getDataDash()
      .then((data) => {
        //console.log(data);
        setGraficas(data.data);
        setIsLoading(false);
      })
      .catch((data) => {
        console.log(data);
        setGraficas([]);
        setIsLoading(false);
      });
  }, []);

  let pedidosUltimos_6_Meses, pedidosUltimos_6_MesesNombre;
  let pedidosUltimo_Mes, pedidosUltimo_MesNombre;
  let pedidosUltimos_7_Dias, pedidosUltimos_7_DiasNombre;
  let topProductos, topProductosNombre;
  let topProductosCalificacion;
  let topClientes;

  if (graficas["pedidosUltimos_6_Meses"] != undefined) {
    const pedidos = JSON.parse(graficas["pedidosUltimos_6_Meses"]);
    pedidosUltimos_6_Meses = pedidos.map((data) => {
      return data.TOTAL_VENTAS;
    });

    pedidosUltimos_6_MesesNombre = pedidos.map((data) => {
      return numberToMes(data.MES, data.ANIO);
    });
  }

  if (graficas["pedidosUltimo_Mes"] != undefined) {
    const pedidosMes = JSON.parse(graficas["pedidosUltimo_Mes"]);
    //console.log(pedidosMes);

    pedidosUltimo_Mes = pedidosMes.map((item) => {
      return item.CANTIDAD;
    });

    pedidosUltimo_MesNombre = pedidosMes.map((item, i) => {
      return `Sem ${i + 1} (${item.DIAS})`;
    });
  }

  if (graficas["pedidosUltimos_7_Dias"] != undefined) {
    const pedidosSemana = JSON.parse(graficas["pedidosUltimos_7_Dias"]);
    pedidosUltimos_7_Dias = pedidosSemana.map((item) => {
      return item.CANTIDAD;
    });

    pedidosUltimos_7_DiasNombre = pedidosSemana.map((item) => {
      return obtenerNombreDia(item.DIA, item.MES);
    });
    pedidosUltimos_7_Dias.reverse();
    pedidosUltimos_7_DiasNombre.reverse();
  }

  if (graficas["topProductos"] != undefined) {
    const topProds = JSON.parse(graficas["topProductos"]);
    //console.log(topProds);

    topProductos = topProds.map((item) => {
      return item.TOTAL_VENTAS;
    });
    topProductosNombre = topProds.map((item) => {
      return item.DESC;
    });
  }

  if (graficas["topProductosCalificacion"] != undefined) {
    const topProds = JSON.parse(graficas["topProductosCalificacion"]);
    //console.log("topProductosCalificacion: ", topProds);
    topProductosCalificacion = topProds.map((item) => {
      return dataTopPros("", item.NOMBRE, item.CATEGORIA, item.PROMEDIO);
    });
  }

  if (graficas["topClientes"] != undefined) {
    const topCli = JSON.parse(graficas["topClientes"]);
    //console.log("clientes: ", topCli);
    topClientes = topCli.map((item) => {
      //console.log(dataTop(item.IMG, item.NOMBRE, item.CANTIDAD));
      return dataTop(item.IMG, item.NOMBRE, item.CANTIDAD);
    });
  }

  return (
    <>
      <Row style={{ display: "" }}>
        <Col sm={12} md={12}>
          <Row>
            <Col xs={12} sm={6} md={3} className="pt-4 pt-sm-4 pt-md-4">
              <Paper
                className=" shadow-sm mt-2 p-3 d-flex justify-content-between flex-column text-dark me-2"
                elevation={5}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#fff",
                }}
              >
                <div>
                  <h5 className="font-AvenirMedium  text-center">
                    Pedidos por procesar
                  </h5>
                </div>

                <div className="d-flex justify-content-start flex-row align-content-center algin-align-items-center">
                  <img
                    src={PedidosProceso}
                    height={tamanioIcons}
                    width={tamanioIcons}
                  ></img>
                  <h3 className="text-end font-AvenirBold text-dark m-2">
                    {IsLoading ? (
                      <h1 className="parpadea">...</h1>
                    ) : (
                      graficas["pedidosXprocesar"]
                    )}
                  </h3>
                </div>
                <div className="text-end">
                  <Link
                    to="../monitor_pedidos/"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Button
                      className="font-Avenir btn text-dark pe-0 text-end pe-0 text-end"
                      size="small"
                    >
                      Ir a pedidos
                      <KeyboardArrowRightIcon className="ms-1" />{" "}
                    </Button>
                  </Link>
                </div>
              </Paper>
            </Col>
            <Col xs={12} sm={6} md={3} className="pt-4 pt-sm-4 pt-md-4">
              <Paper
                className=" shadow-sm mt-2 p-3 d-flex justify-content-between flex-column text-dark me-2"
                elevation={5}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#fff",
                }}
              >
                <div>
                  <h5 className="font-AvenirMedium  text-center">
                    Pedidos para hoy
                  </h5>
                </div>

                <div className="d-flex justify-content-start flex-row align-content-center algin-align-items-center">
                  <img
                    src={PedidosHoy}
                    height={tamanioIcons}
                    width={tamanioIcons}
                  ></img>
                  <h3 className="text-end font-AvenirBold text-dark m-2">
                    {IsLoading ? (
                      <h1 className="parpadea">...</h1>
                    ) : (
                      graficas["pedidosHoy"]
                    )}
                  </h3>
                </div>
                <div className="text-end">
                  <Link
                    to="../monitor_pedidos/"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Button
                      className="font-Avenir btn text-dark pe-0 text-end pe-0 text-end"
                      size="small"
                    >
                      Ir a pedidos
                      <KeyboardArrowRightIcon className="ms-1" />{" "}
                    </Button>
                  </Link>
                </div>
              </Paper>
            </Col>
            <Col xs={12} sm={6} md={3} className="pt-4 pt-sm-4 pt-md-4">
              <Paper
                className=" shadow-sm mt-2 p-3 d-flex justify-content-between flex-column text-dark me-2"
                elevation={5}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#fff",
                }}
              >
                <div>
                  <h5 className="font-AvenirMedium  text-center">
                    Pedidos para proximos 7 dias
                  </h5>
                </div>

                <div className="d-flex justify-content-start flex-row align-content-center algin-align-items-center">
                  <img
                    src={Pedidos7dias}
                    height={tamanioIcons}
                    width={tamanioIcons}
                  ></img>
                  <h3 className="text-end font-AvenirBold text-dark m-2">
                    {IsLoading ? (
                      <h1 className="parpadea">...</h1>
                    ) : (
                      graficas["pedidosProxDias"]
                    )}
                  </h3>
                </div>
                <div className="text-end">
                  <Link
                    to="../monitor_pedidos/"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Button
                      className="font-Avenir btn text-dark pe-0 text-end pe-0 text-end"
                      size="small"
                    >
                      Ir a pedidos
                      <KeyboardArrowRightIcon className="ms-1" />{" "}
                    </Button>
                  </Link>
                </div>
              </Paper>
            </Col>
            <Col xs={12} sm={6} md={3} className="pt-4 pt-sm-4 pt-md-4">
              <Paper
                className=" shadow-sm mt-2 mt-md-2 p-3 d-flex justify-content-between flex-column text-dark me-2"
                elevation={5}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#fff",
                }}
              >
                <div>
                  <h5 className="font-AvenirMedium  text-center">
                    Total pedidos terminados
                  </h5>
                </div>

                <div className="d-flex justify-content-start flex-row align-content-center algin-align-items-center">
                  <img
                    src={TotalPedidos}
                    height={tamanioIcons}
                    width={tamanioIcons}
                  ></img>
                  <h3 className="text-end font-AvenirBold text-dark m-2">
                    {IsLoading ? (
                      <h1 className="parpadea">...</h1>
                    ) : (
                      graficas["pedidosTerminados"]
                    )}
                  </h3>
                </div>
                <div className="text-end">
                  <Link
                    to="../monitor_pedidos/"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Button
                      className="font-Avenir btn text-dark pe-0 text-end pe-0 text-end"
                      size="small"
                    >
                      Ir a pedidos
                      <KeyboardArrowRightIcon className="ms-1" />{" "}
                    </Button>
                  </Link>
                </div>
              </Paper>
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={12}>
          <Row>
            <Col xs={12} sm={12} md={6} lg={4} className="pt-4 pt-sm-4 pt-md-4">
              {IsLoading ? (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
              ) : (
                <ChartLine
                  Height={h}
                  titulo="Pedidos terminados en los últimos 6 meses"
                  //Labels={["Ene", "Feb", "Marz", "Abril", "Mayo", "Junio"]}
                  //values={[30, 40, 4, 50, 49, 6]}
                  Labels={pedidosUltimos_6_MesesNombre}
                  values={pedidosUltimos_6_Meses}
                  Color="#26A0FC"
                />
              )}
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} className="pt-4 pt-sm-4 pt-md-4">
              {IsLoading ? (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
              ) : (
                <ChartBar
                  Height={h}
                  titulo="Pedidos terminados en el mes"
                  /*Labels={[
                  "Semana 1  (1-6)",
                  "Semana 2  (1-6)",
                  "Semana 3  (1-6)",
                  "Semana 4 (1-6)",
                ]}*/
                  //values={[30, 40, 4, 50]}
                  Labels={pedidosUltimo_MesNombre}
                  values={pedidosUltimo_Mes}
                  Color="#C6D2EA"
                />
              )}
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} className="pt-4 pt-sm-4 pt-md-4">
              {IsLoading ? (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
              ) : (
                <ChartLine
                  Height={h}
                  titulo="Pedidos terminados en la semana"
                  //Labels={["LUN", "MAR", "MIE", "JUE", "VIE", "SAB", "DOM"]}
                  //values={[30, 40, 4, 50, 49, 6, 100]}
                  Labels={pedidosUltimos_7_DiasNombre}
                  values={pedidosUltimos_7_Dias}
                  Color="#D6AFA5"
                />
              )}
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} className="pt-4 pt-sm-4 pt-md-4">
              {IsLoading ? (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
              ) : (
                <ChartDonut
                  titulo="Productos más vendidos"
                  values={topProductos}
                  Labels={topProductosNombre}
                  Height={h}
                  widthChart={250}
                />
              )}
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} className="pt-4 pt-sm-4 pt-md-4">
              {IsLoading ? (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
              ) : (
                <ChartTop
                  titulo="Productos mejor calificados"
                  rows={topProductosCalificacion}
                  avatarColor={"#99A2AD"}
                  Height={h}
                  ratingChartTop
                  truncate={200}
                />
              )}
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} className="pt-4 pt-sm-4 pt-md-4">
              {IsLoading ? (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
              ) : (
                <ChartTop
                  titulo="Top Clientes Consumo"
                  //rows={[{ img: "", titulos: "", subtitulos: "" }]}
                  rows={topClientes}
                  avatarColor={"#99A2AD"}
                  Height={h}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
