import React from "react";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";

const ChipStatus = ({ text, backgroundColor, Textcolor }) => {
  const StyledChip = styled(Chip)(({}) => ({
    backgroundColor: backgroundColor,
    color: Textcolor,
  }));

  return <StyledChip label={text} />;
};

export default ChipStatus;
