import { LoadingButton } from "@mui/lab";
import { Alert, CardContent, Snackbar, Stack, TextField } from "@mui/material";
import Card from "@mui/material/Card";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import loogo from "../../../assets/logoMichelle.svg";
import { guardar } from "./FnRegistroPlanner";
import { useNavigate } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { styled } from "@mui/material/styles";

const MySwal = withReactContent(Swal);

const RegistroPlanner = () => {
    const [Values, setValues] = useState({
        nombre: "",
        apellido_paterno: "",
        apellido_materno: "",
        empresas_opcion: {
            emp1: true,
            emp2: false,
        },
        id_grupo: "0",
        id_puesto: "0",
        empresa: "0",
        correo: "",
        telefono: "",
        supervisor: false,
    });
    const [Errores, setErrores] = useState({
        nombre: false,
        apellido_paterno: false,
        apellido_materno: false,
        id_grupo: false,
        id_puesto: false,
        empresa: false,
        correo: false,
        telefono: false,
    });

    const [IsGuardando, setIsGuardando] = useState(false);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [mensaje, setMensaje] = useState("");


    const handleInputChange = ({ target }) => {
        const { name, value } = target;

        // Update the respective field in state
        setValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));

        // Clear the error for the specific field when the user starts typing
        setErrores((prevErrors) => ({
            ...prevErrors,
            [name]: false,
        }));
    };

    const GuardarPlanner = () => {
        setIsGuardando(true);
        guardar(Values, Errores, setErrores)
            .then((data) => {
                if (data.codigo == "200") {
                    setIsGuardando(false);
                    MySwal.fire({
                        title: "Correcto",
                        html: data.mensaje,
                        icon: "success",
                        confirmButtoColor: "#3ABE88",
                        showConfirmButton: false,
                        timer: 1700,
                    }).then((result) => {
                        navigate(-1);
                    });
                } else {
                    setMensaje(data.mensaje);
                    setOpen(true);
                    setIsGuardando(false);
                }
            })
            .catch((data) => {
                // alert(data.mensaje);
                setMensaje(data.mensaje);
                setOpen(true);
                setIsGuardando(false);
            });
    }
    const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
        ({ theme }) => ({
            "& .MuiSnackbar-root": {
                top: theme.spacing(15),
            },
        })
    );

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        document.title = `Registrar planner`;
    }, []);
    return (
        <>
            <StyledSnackbar
                direction="right"
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
                    {mensaje}
                </Alert>
            </StyledSnackbar>
            <Card className="shadow-lg p-4 m-auto mt-5" sx={{ maxWidth: 450 }}>
                <center>
                    <img
                        src={loogo}
                        className="my-4"
                        width={250}
                        style={{ margin: "auto" }}
                    />
                </center>
                <CardContent>
                    <Row className="p-3">
                        {/* Nombre */}
                        <Col xs={12} md={12} className="p-3">
                            <TextField
                                fullWidth
                                name="nombre"
                                label="Nombre"
                                variant="outlined"
                                value={Values.nombre}
                                error={Errores.nombre}
                                helperText={Errores.nombre ? "Nombre no valido" : ""}
                                onChange={handleInputChange}
                            />
                        </Col>

                        {/* Apellido paterno */}
                        <Col xs={12} md={12} className="p-3">
                            <TextField
                                fullWidth
                                name="apellido_paterno"
                                label="Apellido paterno"
                                variant="outlined"
                                value={Values.apellido_paterno}
                                error={Errores.apellido_paterno}
                                helperText={
                                    Errores.apellido_paterno ? "Apellido paterno no valido" : ""
                                }
                                onChange={handleInputChange}
                            />
                        </Col>

                        {/* Apellido materno */}
                        <Col xs={12} md={12} className="p-3">
                            <TextField
                                fullWidth
                                name="apellido_materno"
                                label="Apellido materno"
                                variant="outlined"
                                value={Values.apellido_materno}
                                error={Errores.apellido_materno}
                                helperText={
                                    Errores.apellido_materno ? "Apellido materno no valido" : ""
                                }
                                onChange={handleInputChange}
                            />
                        </Col>

                        {/* Correo */}
                        <Col xs={12} md={12} className="p-3">
                            <TextField
                                fullWidth
                                name="correo"
                                label="Correo electrónico"
                                variant="outlined"
                                helperText={Errores.correo ? "Correo incorrecto" : ""}
                                value={Values.correo}
                                error={Errores.correo}
                                onChange={handleInputChange}
                            />
                        </Col>

                        {/* Teléfono */}
                        <Col xs={12} md={12} className="p-3">
                            <TextField
                                fullWidth
                                name="telefono"
                                label="Teléfono"
                                variant="outlined"
                                inputProps={{ maxLength: 10 }}
                                helperText={Errores.telefono ? "Teléfono inválido" : ""}
                                value={Values.telefono}
                                error={Errores.telefono}
                                onChange={handleInputChange}
                            />
                        </Col>
                    </Row>
                    <Stack
                        className="p-3"
                        spacing={2}

                    >
                        <LoadingButton
                            loading={IsGuardando}
                            loadingPosition="start"
                            onClick={() => GuardarPlanner()}
                            className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
                            variant="contained"
                            xs={{ with: "100$" }}
                        >
                            <span className={IsGuardando ? "px-4" : "px-2"}>
                                {IsGuardando ? "Guardando..." : "Registrarse"}
                            </span>
                        </LoadingButton>
                    </Stack>
                </CardContent>
            </Card>
        </>
    );
};

export default RegistroPlanner;