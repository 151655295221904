import validator from "validator";
import { postdData } from "../../../Context/backend";

export const guardar = async (Values, Errores, setErrores) => {
    let error = false;

    /* REQUERIDOS */
    if (!Values.nombre || Values.nombre == "") {
        let Errores2 = Errores;

        Errores2.nombre = true;
        setErrores(Errores2);
        error = true;
    }

    if (!Values.apellido_paterno || Values.apellido_paterno == "") {
        let Errores2 = Errores;

        Errores2.apellido_paterno = true;
        setErrores(Errores2);
        error = true;
    }

    if (!Values.apellido_paterno || Values.apellido_paterno == "") {
        let Errores2 = Errores;

        Errores2.apellido_paterno = true;
        setErrores(Errores2);
        error = true;
    }

    if (!Values.correo || Values.correo == "") {
        Errores.correo = true;
        setErrores(Errores);
        error = true;
    } else {
        if (!validator.isEmail(Values.correo)) {
            Errores.correo = true;
            setErrores(Errores);
            error = true;
        }
    }
    if (!Values.telefono || Values.telefono == "") {
        Errores.telefono = true;
        setErrores(Errores);
        error = true;
    } else {
        if (!validator.isNumeric(Values.telefono)) {
            Errores.telefono = true;
            setErrores(Errores);
            error = true;
        } else if (Values.telefono.length != 10) {
            Errores.telefono = true;
            setErrores(Errores);
            error = true;
        }
    }

    if (!error) {


        let data = [];
        const body = Values;
        console.log("Datos", body);
        const url = "administradores/managers/InsertarPlanner"


        const res = await postdData(url, body);
        console.log(res);
        return new Promise((resolve, reject) => {
            if (!res.error) {
                data = res.data;
                console.log(data);
                resolve(data);
            } else {
                reject(res);
            }
        });
    } else {
        return new Promise((resolve, reject) => {
            resolve({ mensaje: "Completa todos los campos requeridos" });
        });
    }
};