import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { getWeddingPlanners } from "../Funciones/Administradores"; // Método para obtener datos

const SelectWeddingPlanner = ({
    Value,
    Error,
    handlInputChange,
    Name = "id_wedding_planner",
    size = "small",
}) => {
    const opcionInicial = { VALOR: 0, NOMBRE: "Seleccionar Wedding Planner" };
    const [ValorFiltrado, setFiltro] = useState();
    const [Planners, setPlanners] = useState({
        cargado: false,
        lista: [opcionInicial],
    });

    // Cargar datos del endpoint
    const cargarPlanners = () => {
        getWeddingPlanners()
            .then((resp) => {
                let list = JSON.parse(resp.data);
                if (list.length !== 0) {
                    setPlanners({ cargado: true, lista: [...list] });
                } else {
                    setPlanners({
                        cargado: true,
                        lista: [{ VALOR: 0, NOMBRE: "Sin Wedding Planners disponibles" }],
                    });
                }
            })
            .catch(() => {
                setPlanners({
                    cargado: true,
                    lista: [{ VALOR: 0, NOMBRE: "Sin Wedding Planners disponibles" }],
                });
            });
    };

    useEffect(() => {
        cargarPlanners();
    }, []);

    useEffect(() => {
        if (Value === "" || Value === "0" || Value === 0) {
            setPlanners({
                cargado: false,
                lista: [],
            });
            cargarPlanners();
        }
    }, [Value]);

    const getOpcion = () => {
        const opcion = Planners.lista.find((x) => x.VALOR == Value);
        if (opcion) {
            return opcion;
        } else {
            return null;
        }
    };

    return Planners.cargado ? (
        <Autocomplete
            size={size}
            defaultValue={getOpcion}
            isOptionEqualToValue={(option, value) => {
                return option.VALOR == value.VALOR;
            }}
            inputValue={ValorFiltrado}
            onChange={(e, value) => {
                if (value) {
                    handlInputChange({
                        target: {
                            name: Name,
                            value: String(value.VALOR),
                            nombre: value.NOMBRE,
                        },
                    });
                } else {
                    handlInputChange({ target: { name: Name, value: "", nombre: "" } });
                }
            }}
            onInputChange={(event, newInputValue) => {
                setFiltro(newInputValue);
            }}
            options={Planners.lista}
            getOptionLabel={(option) => {
                return option.NOMBRE;
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={Error}
                    helperText={Error ? "Selecciona un Wedding Planner" : ""}
                    label="Wedding Planner"
                />
            )}
        />
    ) : (
        <TextField fullWidth disabled={true} value={"Cargando..."} />
    );
}
export default SelectWeddingPlanner;