import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import EditCalendarOutlinedIcon from "@mui/icons-material/EditCalendarOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";

const Menu = ({ closeDialog, handleOpenModal ,handleOpenModalEstatus,handleEliminar}) => {
  return (
    <div>
      <Dialog onClose={closeDialog} open={true}>
        <DialogContent>
          <h6 className="font-AvenirBold">Selecciona una acción</h6>
          <ListItem disableGutters>
            <ListItemButton
              onClick={() => {
                handleOpenModal();
                closeDialog();
              }}
              className="ps-0"
            >
              <ListItemAvatar>
                <Avatar>
                  <EditCalendarOutlinedIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Editar" />
            </ListItemButton>
          </ListItem>
          <ListItem disableGutters>
            <ListItemButton  onClick={() => {
                handleOpenModalEstatus();
                closeDialog();
              }}
              className="ps-0">
              <ListItemAvatar>
                <Avatar>
                  <AutorenewOutlinedIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Cambiar estatus" />
            </ListItemButton>
          </ListItem>
          <ListItem disableGutters>
            <ListItemButton  onClick={() => {
                handleEliminar();
                closeDialog();
              }}
              className="ps-0">
              <ListItemAvatar>
                <Avatar>
                  <DeleteOutlineOutlinedIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Eliminar" />
            </ListItemButton>
          </ListItem>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              closeDialog();
            }}
            autoFocus
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Menu;
