import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { setLocalStorageJWT, setLocalStorage } from "../../../Context/Storage";
import BACKEND_URL from "../../../Context/backend";
import loogo from "../../../assets/logoMichelle.svg";
import { useNavigate } from 'react-router-dom';
import {
    Alert,
    Backdrop,
    CircularProgress,
    Grid,
    Snackbar,
    Typography,
} from "@mui/material";
import { Password } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";

export default function LoginPlanners() {
    const [Values, setValues] = useState({
        //Correo: "juancdlpr@flexor.mx",
        Correo: "",
        Contraseña: "",
        //Contraseña: "123qwe",
    });
    const [openLoading, setOpenLoading] = useState(false);
    const [CorreoError, setCorreoError] = useState(false);
    const [ContraseñaError, setContraseñaError] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [open, setOpen] = useState(false);

    const handlInputChange = ({ target }) => {
        setValues({
            ...Values,
            [target.name]: target.value,
        });

        if (!Values.Correo || Values.Correo == "") {
            setCorreoError(true);
        } else {
            setCorreoError(false);
        }
        if (!Values.Contraseña || Values.Contraseña == "") {
            setContraseñaError(true);
            return;
        } else {
            setContraseñaError(false);
        }
    };
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            clicLogin();
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const clicLogin = () => {
        let error = false;
        if (!Values.Correo || Values.Correo == "") {
            setCorreoError(true);
            error = true;
        }
        if (!Values.Contraseña || Values.Contraseña == "") {
            setContraseñaError(true);
            error = true;
        }
        if (!error) {
            setOpenLoading(true);
            const info = {
                usuario: Values.Correo,
                contraseña: Values.Contraseña,
                key: "planner",
            };
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(info),
            };
            fetch(BACKEND_URL + "acceso/managersPlanners", requestOptions)
                .then((response) => response.json())
                .then((response) => {
                    setOpenLoading(false);
                    if (response.resp.codigo == "200") {
                        console.log(response);

                        setLocalStorageJWT(response.resp.respuesta.token);
                        setLocalStorage("img", response.resp.respuesta.img);
                        setLocalStorage("tipo", response.resp.respuesta.tipo);
                        setLocalStorage("nombre", response.resp.respuesta.nombre);
                        setLocalStorage("key", response.resp.respuesta.key);
                        setLocalStorage("puesto", response.resp.respuesta.puesto);
                        window.location.href = '/';

                    } else {
                        setMensaje(response.resp.mensaje);
                        setOpen(true);
                    }
                })
                .catch((error) => {
                    setOpenLoading(false);
                    setMensaje(
                        "Ha ocurrido un error al conectar con nuestros servidores, intenta mas tarde."
                    );
                    setOpen(true);
                    return;
                });
        }
    };




    React.useEffect(() => {
        document.title = `Acceso Planners`;
    }, []);
    return (
        <>
            <Snackbar
                direction="left"
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
                    {mensaje}
                </Alert>
            </Snackbar>

            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openLoading}
            >
                <CircularProgress color="primary" />
            </Backdrop>
            <div className="mt-5 pt-5"></div>
            <Card className="shadow-lg p-4 m-auto mt-5" sx={{ maxWidth: 350 }}>
               
               <h4 className="text-center fw-bold">Acceso Planners</h4>
                <center>
                    <img
                        src={loogo}
                        className="my-4"
                        width={250}
                        style={{ margin: "auto" }}
                    />
                </center>
                <CardContent>
                    <TextField
                        error={CorreoError}
                        fullWidth
                        id="Correo"
                        name="Correo"
                        label="Correo Electrónico"
                        variant="outlined"
                        value={Values.Correo}
                        onChange={handlInputChange}
                    />
                    <TextField
                        fullWidth
                        error={ContraseñaError}
                        id="Contraseña"
                        name="Contraseña"
                        label="Contraseña"
                        variant="outlined"
                        className="mt-3"
                        type="password"
                        value={Values.Contraseña}
                        onChange={handlInputChange}
                        onKeyDown={handleKeyDown}
                    />
                    <Button
                        fullWidth
                        variant="contained"
                        size="large"
                        className="btn-Crexendo mt-4"
                        onClick={clicLogin}
                    >
                        <Typography
                            style={{
                                fontFamily: "Avenir",
                                textTransform: "none",
                            }}
                        >
                            Iniciar sesión
                        </Typography>
                    </Button>
                    <Link to="/planners/registro" style={{ textDecoration: 'none' }}>
                        <Button
                            fullWidth
                            variant="outline"
                            size="large"
                            className="mt-4"
                        >
                            <Typography
                                style={{
                                    fontFamily: "Avenir",
                                    textTransform: "none",
                                }}
                            >
                                Quiero registrarme
                            </Typography>
                        </Button>
                    </Link>
                </CardContent>
            </Card>
        </>
    );
}
