import React from "react";
import ReactDOM from "react-dom/client";
//import RotePrincipal from "./RutePrincipal";

//import App from "./App";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
//import Privacidad from "./Privacidad";
//import ChangePasswordForm from "./Login/componentes/ChangePasswordForm";
//import BuscarCorreo from "./Login/componentes/BuscarCorre";
//import Registro from "./Login/componentes/Registro";
import RouterManager from "./Manager/Manager";
import RouterPlanner from "./Manager/Planner";
import ReportesPDF from "./Manager/Components/Catalogos/Reportes/ReportesPDF";
import Error404 from "./Includes/Error404";
import AppPlanner from "./Manager/AppPlanner";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        {/*<Route path="/privacidad/*" element={<Privacidad />}></Route>
        <Route path="/registro/*" element={<Registro />}></Route>
        <Route path="/manager/*" element={<>AntiguoManager</>}></Route>
        <Route path="/restablecer-contrasena" element={<BuscarCorreo />} />
        <Route
        path="/cambiar-contrasena/:uuid"
        element={<ChangePasswordForm />}
        />
      */}
        <Route path="/pdf/*" element={<ReportesPDF />}></Route>
        <Route path="/planners/*" element={<AppPlanner />} /> 
        <Route path="/*" element={<RouterManager />} /> 
      </Routes>
    </Router>
  </React.StrictMode>
);
