import PropTypes from "prop-types";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

/**
 * 
 * @param {*} param0 
 * @returns 
 * @description Este SELECT simple solo se utilizará cuando el número de elementos sea menor o igual a 6. En ese caso, puede ser necesario crear un array con la siguiente estructura:
Valor: ID
Texto: DESCRIPCION
 */

const SelectSimple = ({
  options,
  placeholder,
  value,
  handleChange,
  error,
  helperText,
  label,
  name,
  parrafo,
  icono,
  primaryParrafo,
  required,
  readOnly,
  disabled,
  size = "small",
  onOpen,
}) => {

  const getOpcion = () => {
    const opcion = options.lista?.find((x) => x.ID == value);
    if (opcion) {
      return opcion;
    } else {
      return null;
    }
  };

  return (
    <div>
      <FormControl
        size={size}
        fullWidth
        error={error}
        required={required}
      >
        <InputLabel>{label == "" ? placeholder : label}</InputLabel>
        <Select
          size={size}
          defaultValue={getOpcion} 
          value={value == 0 ? "" : value}
          onChange={(e, value) => {
            if (value) {
              handleChange({
                target: {
                  name: name,
                  value: value.props.value,
                  desc: value.props.children,
                },
              });
            } else {
              handleChange({ target: { name: name, value: "" } });
            }
          }}
          label={label == "" ? placeholder : label}
          name={name}
          required={required}
          readOnly={readOnly}
          disabled={disabled}
        >
          {options.map((option) => (
            <MenuItem key={option.ID} value={option.ID}>
              {option.DESCRIPCION}
            </MenuItem>
          ))}
        </Select>

        {helperText && (
          <span className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root">{helperText}</span>
        )}
      </FormControl>
      {parrafo ? (
        <div
          className="div-parrafo-select"
          onClick={onOpen}
          style={{ cursor: "pointer" }}
        >
          {icono ? (
            <img src={icono} alt="icono" className="img-select" />
          ) : (
            <></>
          )}
          <p
            className={
              primaryParrafo ? "parrafo-select-blue" : "parrafo-select-gray"
            }
          >
            {parrafo}
          </p>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

SelectSimple.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  parrafo: PropTypes.string,
  icono: PropTypes.string,
  primaryParrafo: PropTypes.bool,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default SelectSimple;
