import { stringify } from "query-string";
import { getData, postdData } from "../../../../Context/backend";
import { da } from "date-fns/locale";
import dayjs from "dayjs";

///////////////////
/* FUNCIONES GET */
///////////////////
export const getLista = async ( fecha_inicio,fecha_final,iTake, iSkip, Order,query,planer) => {
  //onsole.log(query)
  if (Order != "1" && query != "") {
    Order = "1";
  }
  let data = [];
  let queryParamsObj = {
    fechaI: fecha_inicio,
    fechaF: fecha_final,
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
    id_planner: planer == "" ? 0 : planer,
    
  };
  console.log(queryParamsObj);
  const url = `Eventos/Listar?${stringify(queryParamsObj)}`;
  console.log(url);
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getListaCalendario = async (fecha_inicio, fecha_final) => {
  let data = [];
  let queryParamsObj = {
    fecha_inicio: fecha_inicio,
    fecha_final: fecha_final,
  };

  const url = `Eventos/ListarCalendario?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;

      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getEvento = async (id) => {
  let data = [];
  let queryParamsObj = {
    id: id,
  };
  const url = `Eventos/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  console.log("res", res);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      console.log(data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de este puesto",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        let Value = {
          id: String(data.ID),
          fecha_evento: String(data.FECHA_EVENTO),
          fecha_prueba: String(data.FECHA_PRUEBA),
          weddingPlanner: String(data.ID_WEDDING_PLANNER),
          hora: String(data.HORA_PRUEBA),
          modalidad: String(data.MODALIDAD),
          tipo_evento: String(data.TIPO_EVENTO),
          lugar_cita: String(data.LUGAR),
          potragonistas: String(data.NOMBRE_PROTAGONISTA),
          ubicacion_lugar: String(data.UBICACION_LUGAR),
        };
        resolve(Value);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};


export const getConfig = async () => {
  let data = [];

  const url = `configEmpresas/getDatosPruebaFlorConfig`;
  const res = await getData(url);

  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de este puesto",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        let Value = {
          dias_anticipacion: String(data.DIASDEANTICIPO),
          hora_inicio: String(data.HORAINICIAL),
          hora_fin: String(data.HORAFINAL),
        };
        resolve(Value);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

////////////////////
/* FUNCIONES POST */
////////////////////
export const guardar = async (Values, Errores, setErrores) => {
  let error = false;

  /* REQUERIDOS */

  if (!Values.fecha_evento || Values.fecha_evento == "") {
    Errores.fecha_evento = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.fecha_prueba || Values.fecha_prueba == "") {
    Errores.fecha_prueba = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.weddingPlanner || Values.weddingPlanner == "") {
    Errores.weddingPlanner = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.hora || Values.hora == "") {
    Errores.hora = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.modalidad || Values.modalidad == "") {
    Errores.modalidad = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.tipo_evento || Values.tipo_evento == "") {
    Errores.tipo_evento = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.lugar_cita || Values.lugar_cita == "") {
    Errores.lugar_cita = true;
    setErrores(Errores);
    error = true;
  } else { 
    if (Values.lugar_cita == 2) {
      if (!Values.ubicacion_lugar || Values.ubicacion_lugar == "") {
        Errores.ubicacion_lugar = true;
        setErrores(Errores);
        error = true;
      }
    }
    else{
      Errores.ubicacion_lugar = false;
      setErrores(Errores); 
    }
  }

  if (!Values.potragonistas || Values.potragonistas == "") {
    Errores.potragonistas = true;
    setErrores(Errores);
    error = true;
  }

  if (!error) {
    let data = [];
    Values.id = Number(Values.id);
    const body = {
      ID: Values.id,
      FECHA_EVENTO: dayjs(Values.fecha_evento).format("YYYY-MM-DD"),
      HORA_PRUEBA: Values.hora,
      TIPO_EVENTO: Values.tipo_evento,
      ID_WEDDING_PLANNER: Values.weddingPlanner,
      MODALIDAD: Values.modalidad,
      LUGAR: Values.lugar_cita,
      UBICACION_LUGAR: Values.ubicacion_lugar == undefined ? "" : Values.ubicacion_lugar,
      FECHA_PRUEBA: dayjs(Values.fecha_prueba).format("YYYY-MM-DD"),
      DURACION: Values.duracion,
      NOMBRE_PROTAGONISTA: Values.potragonistas,
    };
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "Eventos/Insertar"
        : "Eventos/Actualizar";
    const res = await postdData(url, body);

    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const cambiarEstatusReserva = async (id, estatus) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      ID: id,
      ESTATUS: estatus,
    };
    const url = `Eventos/CambiarEstatus?${stringify(queryParamsObj)}`;
    const res = await postdData(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const cancelarReserva = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      ID: id,
    };
    const url = `Eventos/Eliminar?${stringify(
      queryParamsObj
    )}`;
    const res = await postdData(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const actualizarHoraReserva = async (id, fecha) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      ID: id,
      FECHA: fecha,
    };
    const url = `reservaciones/actualizarFechaReserva?${stringify(
      queryParamsObj
    )}`;
    const res = await postdData(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        console.log(data);
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};
