import { useEffect, useState, useRef } from "react";
import { getLocalStorageJWT } from "../Context/Storage";
import StartLoad from "../Includes/StartLoad";
import LoginPlanners from "./Components/Login/LoginPlanners";
import Menu from "./includes/Menu";
import { PermissionsProvider } from "../Context/PermissionsContext";
import { styled } from "@mui/material/styles";
import { Route, Routes } from "react-router-dom";
import RegistroPlanner from "./Components/RegistroPlanner/RegistroPlanner";
import Error404 from "../Includes/Error404";

const AppPlanner = () => {
  return (
    <Routes>
      <Route index element={<LoginPlanners />} />
      <Route path="/registro" element={<RegistroPlanner />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};
export default AppPlanner;
