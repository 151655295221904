import { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/es";
import dayjs from "dayjs";
import Menu from "./Menu";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { cancelarReserva } from "../Funciones/Funciones";
const Calendario = ({
  events,
  handleOpenModal,
  setInfoEvent,
  setAlert,
  setViewStartDate,
  setViewEndDate,
  infoConfig,
  handleOpenModalEstatus,
}) => {
  const MySwal = withReactContent(Swal);
  const [Eventos, setEventos] = useState([]);
  const [openModalMenu, setOpenModalMenu] = useState(false);

  const handleEventDrop = (info) => {
    const eventStart = info.event.start;
    const today = new Date();
    const oneMonthLater = new Date();
    oneMonthLater.setMonth(today.getMonth() + 1);

    if (eventStart <= oneMonthLater) {
      setAlert({
        open: true,
        mensaje:
          "No se puede mover un evento a una fecha dentro del próximo mes",
        type: "warning",
      });
      info.revert();
    }
  };

  const handleDateClick = (info) => {
    const clickedDate = dayjs(info.dateStr);
    const clickedTime = dayjs(info.date).format("HH:mm:ss");

    const today = dayjs();
    const thirtyDaysLater = today.add(infoConfig.dias_anticipacion, "day");

    if (clickedDate.isBefore(thirtyDaysLater, "day")) {
      setAlert({
        open: true,
        mensaje: `No se puede crear un evento en una fecha dentro de los próximos ${infoConfig.dias_anticipacion} días`,
        type: "warning",
      });
      return;
    }

    setInfoEvent({
      id: "",
      fecha_evento: clickedDate.format("YYYY-MM-DD"),
      fecha_prueba: clickedDate.format("YYYY-MM-DD"),
      hora: clickedTime,
    });
    handleOpenModal(true);
  };

  const handleEventClick = (info) => {
    setOpenModalMenu(true);
    setInfoEvent({
      id: info.event.id,
    });
  };

  const handleDatesSet = (info) => {
    setViewStartDate(dayjs(info.view.activeStart).format("YYYY-MM-DD"));
    setViewEndDate(dayjs(info.view.activeEnd).format("YYYY-MM-DD"));
  };

  useEffect(() => {
    let FilteredData = events.map((row) => {
      return {
        id: row.ID,
        title: row.NOMBRE_PROTAGONISTA,
        start: row.FECHA_PRUEBA,
        end: row.FECHA_FIN,
        hora: row.HORA,
        folio: row.FOLIO,
        estatus: row.ESTATUS,
        resourceEditable: true,
        backgroundColor:
          row.ESTATUS == 1
            ? "#555452"
            : row.ESTATUS == 2
            ? "#76E09A"
            : row.ESTATUS == 3
            ? "#EE6262"
            : row.ESTATUS == 4 && "#EE9562",
        borderColor:
          row.ESTATUS == 1
            ? "#555452"
            : row.ESTATUS == 2
            ? "#76E09A"
            : row.ESTATUS == 3
            ? "#EE6262"
            : row.ESTATUS == 4 && "#EE9562",
        className:
          row.ESTATUS == 1
            ? "sin_antender"
            : row.ESTATUS == 2
            ? "atendido"
            : row.ESTATUS == 3
            ? "cancelado"
            : row.ESTATUS == 4 && "cancelado-automatico",
      };
    });
    FilteredData = [...FilteredData];
    setEventos(FilteredData);
    // console.log(`File: ~ 459 -->`, "render");
  }, [events]);
  const EliminarEvento = (id) => {
    MySwal.fire({
      title: "Eliminar",
      text: "¿Deseas eliminar el evento?, no se podra deshacer la accion",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#c9a396",
      denyButtonColor: "#979797",
    }).then((result) => {
      if (result.isConfirmed) {
        cancelarReserva(id)
          .then(() => {
            MySwal.fire({
              title: "Correcto",
              html: "Se elimino correctamente el evento",
              icon: "success",
              confirmButtonColor: "#c9a396",
              denyButtonColor: "#979797",
              showConfirmButton: true,
              timer: 10000,
            });
          })
          .catch((error) => {
            MySwal.fire({
              title: "Error",
              html: "Hubo un error al eliminar",
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              timer: 10000,
            });
          });
      }
    });
  };
  return (
    <>
      <FullCalendar
        initialView="dayGridMonth"
        height={"auto"}
        handleWindowResize={true}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        slotLabelFormat={{
          hour: "numeric",
          minute: "2-digit",
          meridiem: "short",
          hour12: true,
        }}
        buttonText={{ today: "HOY", month: "Mes", week: "Sem", day: "Día" }}
        events={Eventos}
        initialDate={new Date()}
        locale={esLocale}
        editable={false}
        droppable={false}
        eventDurationEditable={false}
        eventResizableFromStart={false}
        slotMinTime={infoConfig.hora_inicio}
        slotMaxTime={infoConfig.hora_fin}
        slotDuration="00:30:00"
        slotLabelInterval="00:15:00"
        dateClick={handleDateClick}
        eventClick={handleEventClick}
        eventDrop={handleEventDrop}
        datesSet={handleDatesSet}
        allDaySlot={false}
      />
      {openModalMenu && (
        <Menu
          closeDialog={() => setOpenModalMenu(false)}
          handleOpenModal={handleOpenModal}
          handleOpenModalEstatus={handleOpenModalEstatus}
          handleEliminar={EliminarEvento}
        />
      )}
    </>
  );
};

export default Calendario;
