import React, { useEffect, useState } from "react";

import PedidosProceso from "../../assets/PedidosProceso.svg";
import PedidosHoy from "../../assets/PedidosHoy.svg";
import Pedidos7dias from "../../assets/Pedidos7dias.svg";
import TotalPedidos from "../../assets/TotalPedidos.svg";

import eventos_atendidos from "../../assets/eventos_atendidos.svg";
import eventos_cancelados from "../../assets/eventos_cancelados.svg";
import eventos_proximos from "../../assets/eventos_proximos.svg";
import eventos_registrados from "../../assets/eventos_registrados.svg";

import { Col, Row } from "react-bootstrap";
import { Button, LinearProgress, Paper } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { json, Link } from "react-router-dom";
import { Box } from "@material-ui/core";
import ChartLine from "./Charts/ChartLine";
import TableEventos from "./Charts/TableEventos";
import ChartBar from "./Charts/ChartBar";
import { getDataDash, getDataDashEventos } from "./funciones/dashboard";

const DashboardEventos = () => {
  const [IsLoading, setIsLoading] = useState(false);
  const [graficas, setGraficas] = useState([]);
  const [totales, setTotales] = useState({
    total_registrados: 0,
    total_proximos: 0,
    total_atendidos: 0,
    total_cancelados: 0,
    mes: [],
    anio: { labels: [], values: [] },
    canceladas: { labels: [], values: [] },
    semana: [],
  });
  const [cargado, setCargado] = useState(false);
  const tamanioIcons = 40;
  const h = "400px";

  useEffect(() => {
    document.title = "Dashboard";
    getDataDashEventos()
      .then((data) => {
        // console.log(data);
        //console.log(data.data);

        var TOTALES = JSON.parse(data.data.totales);
        var ANUALES = JSON.parse(data.data.anio);
        var CANCELADO = JSON.parse(data.data.cancelados);
        var SEMANA = JSON.parse(data.data.semana);
        var MES = JSON.parse(data.data.mes);
        console.log("SEMANA", SEMANA);
        console.log("MES", MES);

        const labels = ANUALES.map((row) => `${row.Mes}`);
        const data_anuales = ANUALES.map((row) => row.TotalEventos);

        const labels_candelados = CANCELADO.map((row) => `${row.Mes}`);
        const data_anuales_cancelados = CANCELADO.map(
          (row) => row.TotalEventos
        );

        const semana = SEMANA.map((row) => {
          return {
            titulos: row.NOMBRE_PROTAGONISTA,
            img: null,
            subtitulos:row.FECHA_PRUEBA+" "+row.HORA_PRUEBA,
            puntos: 0,
          };
        });
        const mes = MES.map((row) => {
          return {
            titulos: row.NOMBRE_PROTAGONISTA,
            img: null,
            subtitulos:row.FECHA_PRUEBA+" "+row.HORA_PRUEBA,
            puntos: 0,
          };
        });

      //  console.log(labels_candelados); // ['nov 2023', 'dic 2023', 'ene 2024', 'feb 2024']
       // console.log(data_anuales_cancelados); // [5, 2, 0, 3]

        setTotales({
          total_registrados: TOTALES[0].TOTAL,
          total_proximos: TOTALES[0].TOTAL_PROXIMOS,
          total_atendidos: TOTALES[0].TOTAL_ATENDIDOS,
          total_cancelados: TOTALES[0].TOTAL_CANCELADOS,
          mes:mes,
          semana: semana,
          anio: { labels: labels, values: data_anuales },
          canceladas: {
            labels: labels_candelados,
            values: data_anuales_cancelados,
          },
        });

        setIsLoading(false);
      })
      .catch((data) => {
        console.log(data);
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="container-fluid px-3 px-md-5">
      <Row style={{ display: "" }}>
        <Col sm={12} md={12}>
          <Row>
            <Col xs={12} className="pt-4 pt-sm-4 pt-md-4">
              <h3 className="font-AvenirBold ">Dashboard</h3>
            </Col>
            <Col xs={12} sm={6} md={3} className="pt-4 pt-sm-4 pt-md-4 mb-4">
              <Paper
                className=" shadow mt-2 p-3 d-flex justify-content-between flex-column text-dark me-2"
                elevation={5}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#fff",
                }}
              >
                <div>
                  <h5 className="font-AvenirBold ">TOTAL</h5>
                  <h5 className="font-AvenirBold ">Eventos registrados</h5>
                </div>
                <div className="d-flex justify-content-start flex-row align-content-center algin-align-items-center">
                  <img
                    src={eventos_registrados}
                    height={tamanioIcons}
                    width={tamanioIcons}
                  ></img>
                  <h3 className="text-end font-AvenirBold text-dark m-2">
                    {IsLoading ? (
                      <h1 className="parpadea">...</h1>
                    ) : (
                      totales.total_registrados
                    )}
                  </h3>
                </div>
                <div className="text-end">
                  <Link
                    to="../eventos/"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Button
                      className="font-AvenirMedium btn pe-0 text-end pe-0 text-end"
                      size="small"
                      style={{ color: "#65748b" }}
                    >
                      Ver eventos
                      <KeyboardArrowRightIcon className="ms-1" />{" "}
                    </Button>
                  </Link>
                </div>
              </Paper>
            </Col>

            <Col xs={12} sm={6} md={3} className="pt-4 pt-sm-4 pt-md-4  mb-4">
              <Paper
                className=" shadow mt-2 p-3 d-flex justify-content-between flex-column text-dark me-2"
                elevation={5}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#fff",
                }}
              >
                <div>
                  <h5 className="font-AvenirBold ">TOTAL</h5>
                  <h5 className="font-AvenirBold ">Eventos próximos</h5>
                </div>
                <div className="d-flex justify-content-start flex-row align-content-center algin-align-items-center">
                  <img
                    src={eventos_proximos}
                    height={tamanioIcons}
                    width={tamanioIcons}
                  ></img>
                  <h3 className="text-end font-AvenirBold text-dark m-2">
                    {IsLoading ? (
                      <h1 className="parpadea">...</h1>
                    ) : (
                      totales.total_proximos
                    )}
                  </h3>
                </div>
                <div className="text-end">
                  <Link
                    to="../eventos/"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Button
                      className="font-AvenirMedium btn pe-0 text-end pe-0 text-end"
                      size="small"
                      style={{ color: "#65748b" }}
                    >
                      Ver eventos
                      <KeyboardArrowRightIcon className="ms-1" />{" "}
                    </Button>
                  </Link>
                </div>
              </Paper>
            </Col>

            <Col xs={12} sm={6} md={3} className="pt-4 pt-sm-4 pt-md-4  mb-4">
              <Paper
                className=" shadow mt-2 p-3 d-flex justify-content-between flex-column text-dark me-2"
                elevation={5}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#fff",
                }}
              >
                <div>
                  <h5 className="font-AvenirBold ">TOTAL</h5>
                  <h5 className="font-AvenirBold ">Eventos atendidos</h5>
                </div>
                <div className="d-flex justify-content-start flex-row align-content-center algin-align-items-center">
                  <img
                    src={eventos_atendidos}
                    height={tamanioIcons}
                    width={tamanioIcons}
                  ></img>
                  <h3 className="text-end font-AvenirBold text-dark m-2">
                    {IsLoading ? (
                      <h1 className="parpadea">...</h1>
                    ) : (
                      totales.total_atendidos
                    )}
                  </h3>
                </div>
                <div className="text-end">
                  <Link
                    to="../eventos/"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Button
                      className="font-AvenirMedium btn pe-0 text-end pe-0 text-end"
                      size="small"
                      style={{ color: "#65748b" }}
                    >
                      Ver eventos
                      <KeyboardArrowRightIcon className="ms-1" />{" "}
                    </Button>
                  </Link>
                </div>
              </Paper>
            </Col>

            <Col xs={12} sm={6} md={3} className="pt-4 pt-sm-4 pt-md-4  mb-4">
              <Paper
                className=" shadow mt-2 p-3 d-flex justify-content-between flex-column text-dark me-2"
                elevation={5}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#fff",
                }}
              >
                <div>
                  <h5 className="font-AvenirBold ">TOTAL</h5>
                  <h5 className="font-AvenirBold ">Eventos Cancelados</h5>
                </div>
                <div className="d-flex justify-content-start flex-row align-content-center algin-align-items-center">
                  <img
                    src={eventos_cancelados}
                    height={tamanioIcons}
                    width={tamanioIcons}
                  ></img>
                  <h3 className="text-end font-AvenirBold text-dark m-2">
                    {IsLoading ? (
                      <h1 className="parpadea">...</h1>
                    ) : (
                      totales.total_cancelados
                    )}
                  </h3>
                </div>
                <div className="text-end">
                  <Link
                    to="../eventos/"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Button
                      className="font-AvenirMedium btn pe-0 text-end pe-0 text-end"
                      size="small"
                      style={{ color: "#65748b" }}
                    >
                      Ver eventos
                      <KeyboardArrowRightIcon className="ms-1" />{" "}
                    </Button>
                  </Link>
                </div>
              </Paper>
            </Col>

            <Col xs={12} sm={12} md={6} className="pt-4 pt-sm-4 pt-md-4  mb-4">
              {IsLoading ? (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
              ) : (
                <TableEventos
                  titulo="Eventos próximos en el mes"
                  rows={ totales.mes} 
                  avatarColor={"#99A2AD"}
                  Height={h}
                />
              )}
            </Col>

            <Col xs={12} sm={12} md={6} className="pt-4 pt-sm-4 pt-md-4  mb-4">
              {IsLoading ? (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
              ) : (
                <TableEventos
                  titulo="Eventos próximos en la semana"
                  avatarColor="#fe8d27"
                  rows={ totales.semana}
                  Height={h}
                />
              )}
            </Col>

            <Col xs={12} className="pt-4 pt-sm-4 pt-md-4">
              {IsLoading ? (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
              ) : (
                <ChartBar
                  Height={h}
                  titulo="Eventos en el año"
                  Labels={totales.anio.labels}
                  values={totales.anio.values}
                  values2={totales.canceladas.values}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default DashboardEventos;
