export const estadosEvento = {
  1: { name: "Sin atender", color: "#555452", colorText: "#FFFFFF" }, // Ejemplo de color rojo
  2: { name: "Atendido", color: "#76E09A", colorText: "#151916" }, // Ejemplo de color verde
  3: { name: "Cancelado", color: "#EE6262", colorText: "#FFFFFF" }, // Ejemplo de color azul
  4: { name: "Cancelado automatico", color: "#EE9562", colorText: "#FFFFFF" }, // Ejemplo de color naranja
};

  export const modalidad = {
    1: "Presencial",
    2: "Virtual"
  };

  export const tipo_evento = {
    1: "Fiesta de XV años",
    2: "Boda",
    3: "Graduación",
    4: "Cumpleaños",
    5: "Aniversario",
    6: "Bautizo",
    7: "Baby shower",
    8: "Otro"
  };