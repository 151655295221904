import { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { BasicDatePicker } from "../../../includes/DatePicker";
import { BasicTimePicker } from "../../../includes/TimePicker";
import SelectGenerico from "../../../includes/SelectGenerico";
import SelectWeddingPlanner from "../../Catalogos/Administradores/Componentes/SelectWeddingPlanner";
import { LoadingButton } from "@mui/lab";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { getEvento, guardar } from "../Funciones/Funciones";
import Loading from "../../../../Includes/Loading";
import ModalEstatus from "./ModalEstatus";

const modalidad = [
  { ID: "1", DESCRIPCION: "Presencial" },
  { ID: "2", DESCRIPCION: "Virtual" },
];

export const tipo_evento = [ 
  { ID: "2", DESCRIPCION: "Boda" },
  { ID: "1", DESCRIPCION: "Fiesta de XV años" },
  { ID: "3", DESCRIPCION: "Graduación" },
  { ID: "4", DESCRIPCION: "Cumpleaños" },
  { ID: "5", DESCRIPCION: "Aniversario" },
  { ID: "6", DESCRIPCION: "Bautizo" },
  { ID: "7", DESCRIPCION: "Baby shower" },
  { ID: "8", DESCRIPCION: "Otro" },
];

const lugar_cita = [
  { ID: "1", DESCRIPCION: "Oficinas Michelle Pourroy" },
  { ID: "2", DESCRIPCION: "Otro" },
];

const MySwal = withReactContent(Swal);

const ModalEvento = ({
  infoEvent,
  closeDialog,
  handlInputChange,
  setInfoEvent,
  Errores,
  SetErrores,
  infoConfig,
  getEventos,
}) => {
  const [saving, setSaving] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);

  const GuardarEvento = () => {
    setSaving(true);
    guardar(infoEvent, Errores, SetErrores)
      .then((data) => {
        if (data.codigo == "200") {
          setSaving(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              closeDialog();
              getEventos();
            }
          });
        } else {
          MySwal.fire({
            title: "Advertencia",
            html: data.mensaje,
            icon: "warning",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: true, 
          });
          setSaving(false);
        }
      })
      .catch((data) => {
        console.log("error", data);
        MySwal.fire({
          title: "Advertencia",
          html: data.mensaje,
          icon: "warning",
          confirmButtoColor: "#3ABE88",
          showConfirmButton: true, 
        });
        setSaving(false);
        console.log(Errores);
      });
  };

  useEffect(() => {
    if (
      infoEvent.id !== undefined &&
      infoEvent.id !== 0 &&
      infoEvent.id !== ""
    ) {
      setIsLoading(true);
      getEvento(infoEvent.id)
        .then((resp) => {
          if (resp?.err?.length === 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              closeDialog();
            });
          } else {
            console.log(resp);
            setInfoEvent(resp);
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            closeDialog();
            setIsLoading(false);
          });
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  return (
    <>
      <Dialog onClose={closeDialog} fullWidth maxWidth="xs" open={true}>
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h6 className="fw-bolder f-12">
            {infoEvent.id ? "Editar" : "Nueva"} cita
          </h6>
          <IconButton
            onClick={() => {
              closeDialog();
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {IsLoading ? (
            <Loading />
          ) : (
            <div className="row p-3">
              <div className="col-md-12">
                <BasicDatePicker
                  fecha={infoEvent.fecha_prueba}
                  label={"Fecha de la prueba"}
                  format="DD/MM/YYYY"
                  size="small"
                  setFecha={(e) => {
                    const target = {
                      name: "fecha_prueba",
                      value: e.format("YYYY-MM-DD"),
                    };
                    handlInputChange({ target });
                  }}
                />
              </div>
              <div className="col-md-12 mt-3">
                <BasicDatePicker
                  fecha={infoEvent.fecha_evento}
                  label={"Fecha del evento"}
                  format="DD/MM/YYYY"
                  size="small"
                  setFecha={(e) => {
                    const target = {
                      name: "fecha_evento",
                      value: e.format("YYYY-MM-DD"),
                    };
                    handlInputChange({ target });
                  }}
                />
              </div>
              <div className="col-md-12 mt-3">
                <BasicTimePicker
                  error={Errores.hora}
                  setTime={(e) => {
                    const target = {
                      name: "hora",
                      value: `${e.$H.toString().padStart(2, "0")}:${e.$m
                        .toString()
                        .padStart(2, "0")}`, //
                    };
                    handlInputChange({ target });
                  }}
                  hora={infoEvent.hora}
                  label="Hora de la prueba"
                  fullWidth
                  helpertext={`Solo se permiten horas en punto de ${infoConfig.hora_inicio} a ${infoConfig.hora_fin}`}
                />
              </div>

              <div className="col-md-12 mt-3">
                <SelectWeddingPlanner
                  Name="weddingPlanner"
                  handlInputChange={handlInputChange}
                  Value={infoEvent.weddingPlanner}
                  Error={Errores.weddingPlanner}
                />
              </div>
              <div className="col-md-12 mt-3">
                <TextField
                  label="Potragonistas"
                  variant="outlined"
                  fullWidth
                  size="small"
                  name="potragonistas"
                  inputProps={{ maxLength: 250 }}
                  value={infoEvent.potragonistas}
                  onChange={handlInputChange}
                  error={Errores.potragonistas}
                />
              </div>
              <div className="col-md-12 mt-3">
                <SelectGenerico
                  name="tipo_evento"
                  handleChange={handlInputChange}
                  label="Tipo de evento"
                  options={tipo_evento}
                  value={infoEvent.tipo_evento}
                  error={Errores.tipo_evento}
                  helperText={
                    Errores.tipo_evento ? "Selecciona un tipo de evento" : ""
                  }
                />
              </div>
              <div className="col-md-12 mt-3">
                <SelectGenerico
                  name="modalidad"
                  handleChange={handlInputChange}
                  label="Modalidad"
                  options={modalidad}
                  value={infoEvent.modalidad}
                  error={Errores.modalidad}
                  helperText={
                    Errores.modalidad ? "Selecciona una modalidad" : ""
                  }
                />
              </div>
              <div className="col-md-12  mt-3">
                <SelectGenerico
                  name="lugar_cita"
                  label="Lugar de la cita"
                  handleChange={handlInputChange}
                  options={lugar_cita}
                  value={infoEvent.lugar_cita}
                  error={Errores.lugar_cita}
                  helperText={
                    Errores.lugar_cita ? "Selecciona un lugar de cita" : ""
                  }
                />
              </div>
              {infoEvent.lugar_cita == 2 && (
                <div className="col-md-12  mt-3">
                  <TextField
                    name="ubicacion_lugar"
                    fullWidth
                    label="Ubicación del lugar"
                    size="small"
                    value={infoEvent.ubicacion_lugar}
                    onChange={handlInputChange}
                    error={Errores.ubicacion_lugar}
                    helperText={
                      Errores.ubicacion_lugar
                        ? "Ingresa la ubicación del lugar"
                        : ""
                    }
                  />
                </div>
              )}
              <div className="col-md-12 mt-3">
                <LoadingButton
                  loading={saving}
                  loadingPosition="start"
                  disabled={saving}
                  onClick={() => GuardarEvento()}
                  className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
                  variant="contained"
                  fullWidth
                >
                  <span className={saving ? "px-4" : "px-2"}>
                    {saving
                      ? "Guardando..."
                      : infoEvent.id
                      ? "Guardar"
                      : "Crear"}
                  </span>
                </LoadingButton>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
export default ModalEvento;
