import React, { useState } from "react";
import { Alert, Avatar, Snackbar } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";

import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { TableHead, Tooltip, Grid, MenuItem, Menu } from "@mui/material";
import AccEditar from "../../../assets/AccEditar.svg";
import AccEliminar from "../../../assets/AccEliminar.svg";
import { Link } from "react-router-dom";
import Loading from "../../../includes/Loading";
import Swal from "sweetalert2";
import Switch from "../../../includes/Switch";
import { styled } from "@mui/material/styles";
import Fade from "@mui/material/Fade";
import MoreVert from "@mui/icons-material/MoreVert";
import { firstLeterMayus } from "../../../../Lib/Funciones Generales/Generales";
import { avatarLetters } from "../../../../Lib/Funciones Generales/Generales";
import { cancelarReserva } from "../Funciones/Funciones";
import { Eliminar } from "../../Catalogos/Administradores/Funciones/Administradores";
import withReactContent from "sweetalert2-react-content";
import { estadosEvento, modalidad, tipo_evento } from "../Enums/Eventos";
import ModalEstatus from "./ModalEstatus";
import ChipStatus from "./ChipStatus";

function TablePaginationActions({ count, page, rowsPerPage, onPageChange }) {
  const theme = useTheme();

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function TablaEventos({
  Lista,
  IsLoading,
  setLista,
  setInfoEvent,
  handleOpenModal,
  getListaEventos,
}) {
  function buscarDescripcionPorID(id) {
    // Verificamos si el ID existe en el objeto
    return tipo_evento[id] || "ID no encontrado";
  }

  const MySwal = withReactContent(Swal);

  const [openModalEstatus, setOpenModalEstatus] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [severity, setSeverity] = useState("error");
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - Lista.length) : 0;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const [idRow, setIdRow] = useState();
  const [stateRow, setStateRow] = useState();

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    //console.log(page);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenModalEstatus = () => {
    setOpenModalEstatus(true);
  };

  const handleCloseModalEstatus = () => {
    setOpenModalEstatus(false);
  };

  // se elimina de la tabla en memoria para no consultar nuevamente toda la lista
  const eliminarAdministradorDeTabla = (id) => {
    const filteredLibraries = Lista.filter((item) => {
      return item.ID !== id;
    });
    setLista(filteredLibraries);
  };

  const onChange = (checked, id) => {
    setIdRow(id);
    setStateRow(checked);
    ActivarOrDesactivar(id, checked);
  };
  const ActivarOrDesactivar = (id, checked) => {};

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );
  const EliminarEvento = (id) => {
    MySwal.fire({
      title: "Eliminar",
      text: "¿Deseas eliminar el evento?, no se podra deshacer la accion",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#c9a396",
      denyButtonColor: "#979797",
    }).then((result) => {
      if (result.isConfirmed) {
        cancelarReserva(id)
          .then(() => {
            MySwal.fire({
              title: "Correcto",
              html: "Se elimino correctamente el evento",
              icon: "success",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              timer: 10000,
            });
            getListaEventos();
          })
          .catch((error) => {
            MySwal.fire({
              title: "Error",
              html: "Hubo un error al eliminar",
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              timer: 10000,
            });
          });
      }
    });
  };
  return (
    <>
      {openModalEstatus && (
        <ModalEstatus
          info={{ id: idRow }}
          closeDialog={handleCloseModalEstatus}
          getLista={getListaEventos}
        />
      )}
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <TableContainer component={Paper}>
        <Menu
          open={openMenu}
          anchorEl={anchorEl}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          TransitionComponent={Fade}
        >
          <MenuItem
            onClick={() => {
              handleMenuClose();
            }}
            component={Link}
          >
            <IconButton>
              <img src={AccEditar} />
            </IconButton>
            Editar
          </MenuItem>

          <MenuItem
            onClick={() => {
              handleMenuClose();
            }}
          >
            <IconButton>
              <img src={AccEliminar} />
            </IconButton>
            Eliminar
          </MenuItem>
        </Menu>
        <Table aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell className=" font-Avenir font-AvenirBold" align="left">
                FOLIO
              </TableCell>
              <TableCell className=" font-Avenir font-AvenirBold" align="left">
                FECHA DE PRUEBA
              </TableCell>
              <TableCell className=" font-Avenir font-AvenirBold" align="left">
                HORA
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="left">
                TIPO DE EVENTO
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="left">
                PROTAGONISTA
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="left">
                FECHA DE EVENTO
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="left">
                MODALIDAD
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="left">
                ESTATUS
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="right">
                ACCIONES
              </TableCell>
            </TableRow>
          </TableHead>

          {IsLoading ? (
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" colSpan={12}>
                  <Loading />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {(rowsPerPage > 0
                ? Lista.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : Lista
              ).map((row) => (
                <TableRow key={row.ID}>
                  <TableCell
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                  >
                    {row.FOLIO}
                  </TableCell>

                  <TableCell
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                  >
                    {row.FECHA_PRUEBA}
                  </TableCell>

                  <TableCell component="th" scope="row" className="">
                    {row.HORA_PRUEBA}
                  </TableCell>
                  <TableCell component="th" scope="row" className="">
                    {buscarDescripcionPorID(row.TIPO_EVENTO)}
                  </TableCell>
                  <TableCell component="th" scope="row" className="">
                    {row.NOMBRE_PROTAGONISTA}
                  </TableCell>
                  <TableCell component="th" scope="row" className="">
                    {row.FECHA_EVENTO}
                  </TableCell>
                  <TableCell component="th" scope="row" className="">
                    {modalidad[row.MODALIDAD]}
                  </TableCell>
                  <TableCell component="th" scope="row" className="">
                    <ChipStatus
                      text={estadosEvento[row.ESTATUS].name}
                      backgroundColor={estadosEvento[row.ESTATUS].color}
                      Textcolor={estadosEvento[row.ESTATUS].colorText}
                    />
                  </TableCell>

                  <TableCell align="right">
                    <Grid key={row.ID} className="d-none d-sm-block">
                      <Tooltip title="Editar" placement="top">
                        <IconButton
                          onClick={() => {
                            setInfoEvent({
                              id: row.ID,
                            });
                            handleOpenModal();
                          }}
                        >
                          <img src={AccEditar} />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Cambiar estatus" placement="top">
                        <IconButton
                          onClick={() => {
                            setIdRow(row.ID);
                            handleOpenModalEstatus();
                          }}
                        >
                          <EventAvailableIcon
                            width={20}
                            style={{
                              margin: "auto",
                              color:"black"
                            }}
                          />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Eliminar" placement="top">
                        <IconButton
                          onClick={() => {
                            EliminarEvento(row.ID);
                          }}
                        >
                          <img src={AccEliminar} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid>
                      <DrawerHeader
                        style={{ backgroundColor: "#FFFFFF" }}
                        className="d-xsm-none d-sm-block d-sm-none"
                        onClick={() => {
                          setIdRow(row.ID);
                          setStateRow(row.ACTIVO);
                        }}
                      >
                        <IconButton onClick={handleClick}>
                          <MoreVert />
                        </IconButton>
                      </DrawerHeader>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          )}
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  15,
                  30,
                  100,
                  { label: "Todos", value: -1 },
                ]}
                count={Lista.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "Filas por pagína",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={"Registros por página"}
                labelDisplayedRows={({ from, to, count, page }) => {
                  return `${from} - ${to} de ${count}`;
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
