import { useEffect, useState } from "react";
import DashboardEventos from "./DashboardEventos";
import DashboardAdmin from "./DahsboadrAdmin";

const Dashboard = () => {
  const [puesto, setPuesto] = useState(1);

  useEffect(() => {
    const puesto = localStorage.getItem("puesto");
    setPuesto(puesto);
    console.log(puesto);
  }, []);

  return <>{puesto == 6 ? <DashboardEventos /> : <DashboardAdmin />}</>;
};

export default Dashboard;
