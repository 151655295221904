import { Route, Routes } from "react-router-dom";

import Error404 from "../Includes/Error404";
import LoginManager from "./Components/Login/LoginManager";
import AppManager from "./AppManager";
import RegistroPlanner from "./Components/RegistroPlanner/RegistroPlanner";
import LoginPlanners from "./Components/Login/LoginPlanners";


const RouterManager = () => {
  return (
    <>
      <Routes>
        <Route path="/*" element={<AppManager />} /> 
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default RouterManager;
