import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SelectGenerico from "../../../includes/SelectGenerico";
import { cambiarEstatusReserva } from "../Funciones/Funciones";
import { useState } from "react";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);
const estatus = [
  { ID: "1", DESCRIPCION: "Sin atender" },
  { ID: "2", DESCRIPCION: "Atendido" },
  { ID: "3", DESCRIPCION: "Cancelado" },
  { ID: "4", DESCRIPCION: "Cancelado Automatico" },
];

const ModalEstatus = ({ info, closeDialog, getLista }) => {
  const [selectedEstatus, setSelectedEstatus] = useState("");

  const handleSelectChange = (event) => {
    setSelectedEstatus(event.target.value);
  };
  const cambiarEstatus = () => {
    if (selectedEstatus) {
      MySwal.fire({
        title: "¿Estás seguro?",
        text: "¿Deseas cambiar el estatus?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#c9a396",
        denyButtonColor: "#979797",
      }).then((result) => {
        if (result.isConfirmed) {
          cambiarEstatusReserva(info.id, selectedEstatus)
            .then((info) => {
              if (info.codigo == "200") {
                MySwal.fire({
                  title: "Correcto",
                  html: "Cambio de estatus exitoso",
                  icon: "success",
                  confirmButtonColor: "#3ABE88",
                  showConfirmButton: true,
                  timer: 10000,
                });
                closeDialog();
                getLista();
              }
            })
            .catch((error) => {
              MySwal.fire({
                title: "Error",
                html: "Hubo un error al cambiar el estatus",
                icon: "error",
                confirmButtonColor: "#3ABE88",
                showConfirmButton: true,
                timer: 10000,
              });
            });
        }
      });
    } else {
      MySwal.fire({
        title: "Warning",
        html: "Favor de seleccionar el estatus",
        icon: "warning",
        confirmButtonColor: "#3ABE88",
        showConfirmButton: true,
        timer: 10000,
      });
    }
  };
  return (
    <Dialog onClose={closeDialog} fullWidth maxWidth="xs" open={true}>
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h6 className="fw-bolder f-12">Cambiar Estatus</h6>
        <IconButton
          onClick={() => {
            closeDialog();
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className="row p-3 pt-2">
          <div className="col-md-12  mt-0">
            <SelectGenerico
              label="Seleccionar estatus"
              options={estatus}
              value={selectedEstatus}
              handleChange={handleSelectChange}
            />
          </div>
          <div className="col-md-12 mt-3">
            <Button
              className="btn btn-Crexendo font-AvenirMedium px-4 "
              variant="contained"
              fullWidth
              onClick={cambiarEstatus}
            >
              Guardar
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default ModalEstatus;
