import { useEffect, useState } from "react";
import { BasicDatePicker } from "../../../includes/DatePicker";
import SelectOrdenamiento from "../../../includes/SelectOrdenamiento";
import TablaEventos from "./TablaEventos";
import { getLista } from "../Funciones/Funciones";
import SelectWeddingPlanner from "../../Catalogos/Administradores/Componentes/SelectWeddingPlanner";
import dayjs from "dayjs";

const Tabla = ({ handleOpenModal, setInfoEvent }) => {
  const [IsLoading, setIsLoading] = useState(false);
  const [ValueSelect, setValueSelect] = useState("1");
  const [Lista, setLista] = useState([]);
  const hoy = new Date();
  const fechaInicioInicial = hoy;
  const fechaFinInicial = new Date();
  fechaFinInicial.setMonth(hoy.getMonth() + 1);
  const [fechaInicio, setFechaInicio] = useState(fechaInicioInicial);
  const [fechaFin, setFechaFin] = useState(fechaFinInicial);
  const [weddingPlanner, setWeddingPlanner] = useState(0);

  const getListaEventos = (busqueda = "") => {
    let fechaInicio_ = dayjs(fechaInicio).format("YYYY-MM-DD");
    let fechaFinal_ = dayjs(fechaFin).format("YYYY-MM-DD");

    setIsLoading(true);
    getLista(
      fechaInicio_,
      fechaFinal_,
      0,
      0,
      ValueSelect,
      busqueda,
      weddingPlanner
    )
      .then((resp) => {
        let list = JSON.parse(resp.data);
        setLista(list);
        console.log(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getListaEventos();
  }, [ValueSelect, fechaInicio, fechaFin, weddingPlanner]);

  return (
    <>
      <div className="row p-3">
        <div className="col-md-3 col-sm-12">
          <BasicDatePicker
            fullWidth
            size="small"
            format="DD/MM/YYYY"
            label="Fecha de inicio"
            fecha={fechaInicio}
            setFecha={setFechaInicio}
          />
        </div>
        <div className="col-md-3 col-sm-12">
          <BasicDatePicker
            fullWidth
            size="small"
            format="DD/MM/YYYY"
            label="Fecha fin"
            fecha={fechaFin}
            setFecha={setFechaFin}
          />
        </div>
        <div className="col-md-3 col-sm-12">
          <SelectWeddingPlanner
            handlInputChange={(value) => {
              setWeddingPlanner(value.target.value);
            }}
            Value={weddingPlanner}
            size="small"
          />
        </div>
        <div className="col-md-3 col-sm-12">
          <SelectOrdenamiento
            ValueSelect={ValueSelect}
            setValueSelect={setValueSelect}
            size="small"
          />
        </div>
        <div className="col-12 mt-5">
          <TablaEventos
            IsLoading={IsLoading}
            Lista={Lista ?? []}
            setLista={setLista}
            handleOpenModal={handleOpenModal}
            setInfoEvent={setInfoEvent}
            getListaEventos={getListaEventos}
          />
        </div>
      </div>
    </>
  );
};

export default Tabla;
