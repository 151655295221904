import { useEffect, useState } from "react";
import ModalEvento from "./Componentes/ModalEvento";
import {
  Alert,
  Button,
  Card,
  CardContent,
  Link,
  Snackbar,
  Stack,
  styled,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import Calendario from "./Componentes/Calendario";
import Tabla from "./Componentes/Tabla";
import { getConfig, getListaCalendario } from "./Funciones/Funciones";
import dayjs from "dayjs";
import { ConvertDate } from "../../../Lib/Funciones Generales/ObtenerFecha";
import ModalEstatus from "./Componentes/ModalEstatus";

const Eventos = () => {
  const [infoConfig, setInfoConfig] = useState({
    dias_anticipacion: 0,
    hora_inicio: "",
    hora_fin: "",
  });
  const [viewStartDate, setViewStartDate] = useState(null);
  const [viewEndDate, setViewEndDate] = useState(null);
  const [events, setEvents] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModalEstatus, setOpenModalEstatus] = useState(false);
  const [showVistaTabla, setShowVistaTabla] = useState(false);
  const [infoEvent, setInfoEvent] = useState({
    id: "",
    fecha_evento: "",
    fecha_prueba: "",
    weddingPlanner: 0,
    hora: "00:00",
    modalidad: 0,
    tipo_evento: 0,
    lugar_cita: 0,
    potragonistas: "",
    ubicacion_lugar: "",
  });
  const [Errores, setErrores] = useState({
    fecha_evento: false,
    fecha_prueba: false,
    weddingPlanner: false,
    hora: false,
    modalidad: false,
    tipo_evento: false,
    lugar_cita: false,
    potragonistas: false,
    ubicacion_lugar: false,
  });

  const [alert, setAlert] = useState({
    open: false,
    mensaje: "",
    type: "error",
  });

  const [IsLoading, setIsLoading] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleOpenModalEstatus = () => {
    setOpenModalEstatus(true);
  };

  const handleCloseModalEstatus = () => {
    setOpenModalEstatus(false);
  };
  const handleToggleView = (event, newView) => {
    setShowVistaTabla(newView === "table");
  };

  const getEventos = () => {
    setIsLoading(true);
    if (viewStartDate != null) {
      getListaCalendario(
        dayjs(viewStartDate).format("YYYY-MM-DD"),
        dayjs(viewEndDate).format("YYYY-MM-DD")
      )
        .then((resp) => {
          let list = JSON.parse(resp.data);
          console.log("list", list);
          setEvents(list);
          setIsLoading(false);
        })
        .catch((resp) => {
          setEvents([]);
          setIsLoading(false);
        });
    }
  };

  const getConfigCalendario = () => {
    getConfig()
      .then((resp) => {
        if (resp?.err?.length != 0) {
          setInfoConfig(resp);
        }
      })
      .catch((resp) => {
        console.log("error", resp);
      });
  };

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value;

    if (Name == "fecha_evento" || Name == "fecha_prueba") {
      setInfoEvent({
        ...infoEvent,
        [Name]: ConvertDate(Value),
      });
    }

    setInfoEvent({
      ...infoEvent,
      [Name]: Value,
    });
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  useEffect(() => {
    getEventos();
  }, [viewStartDate]);

  useEffect(() => {
    getConfigCalendario();
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap p-3">
        <div className="p-2">
          <h4 className="text-left font-AvenirBold">Pruebas de flor</h4>
        </div>
        <div className="p-2">
          <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
            <Link to="add" style={{ textDecoration: "none" }}>
              <Button
                onClick={() => {
                  setInfoEvent({
                    id: "",
                    fecha_evento: "",
                    fecha_prueba: "",
                    weddingPlanner: 0,
                    hora: "",
                    modalidad: 0,
                    tipo_evento: 0,
                    lugar_cita: 0,
                    potragonistas: "",
                    ubicacion_lugar: "",
                  });
                  handleOpenModal();
                }}
                fullWidth
                className="btn btn-Crexendo font-AvenirMedium"
                variant="contained"
              >
                Agendar
              </Button>
            </Link>
          </Stack>
          <ToggleButtonGroup
            className="mt-3"
            value={showVistaTabla ? "table" : "calendar"}
            exclusive
            onChange={handleToggleView}
          >
            <ToggleButton value="calendar">
              <CalendarTodayOutlinedIcon />
            </ToggleButton>
            <ToggleButton value="table">
              <FormatListBulletedOutlinedIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      <Card variant="outlined" className="p-0 shadow">
        <CardContent className="p-3">
          {showVistaTabla ? (
            <Tabla
              setInfoEvent={setInfoEvent}
              handleOpenModal={handleOpenModal}
            />
          ) : (
            <div>
              <Calendario
                setInfoEvent={setInfoEvent}
                handleOpenModal={handleOpenModal}
                events={events}
                setAlert={setAlert}
                setViewStartDate={setViewStartDate}
                setViewEndDate={setViewEndDate}
                handleOpenModalEstatus={handleOpenModalEstatus}
                infoConfig={infoConfig}
              />
            </div>
          )}
          <StyledSnackbar
            direction="right"
            open={alert.open}
            autoHideDuration={3000}
            onClose={(event, reason) => {
              if (reason === "clickaway") {
                return;
              }
              setAlert({ ...alert, open: false });
            }}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert
              onClose={() => setAlert({ ...alert, open: false })}
              severity={alert.type}
              sx={{ width: "100%" }}
            >
              {alert.mensaje}
            </Alert>
          </StyledSnackbar>
        </CardContent>
      </Card>

      {openModal && (
        <ModalEvento
          infoConfig={infoConfig}
          handlInputChange={handlInputChange}
          setInfoEvent={setInfoEvent}
          infoEvent={infoEvent}
          closeDialog={handleCloseModal}
          Errores={Errores}
          SetErrores={setErrores}
          getEventos={getEventos}
        />
      )}
      {openModalEstatus && (
        <ModalEstatus
          getLista={getEventos}
          info={infoEvent}
          closeDialog={handleCloseModalEstatus}
        />
      )}
    </>
  );
};

export default Eventos;
