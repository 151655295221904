import { getData } from "../../../../Context/backend";
import { stringify } from "query-string";

export const getDataDash = async () => {
  let data = [];
  const url = "dashboard/getData";
  const res = await getData(url);

  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};
export const getDataDashEventos = async () => {
  let data = [];
  const url = "dashboard/getDataWendding";
  const res = await getData(url);

  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};
