import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers";
import { GetDate } from "../../Lib/Funciones Generales/ObtenerFecha";

import "dayjs/locale/es-mx";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";

export const BasicTimePicker = ({
  hora,
  setTime,
  label,
  format = "HH:mm",
  size = "small",
  disabledd = false,
  fullWidth = false,
  helpertext = "",
  error = false,
}) => {
  const [Value, setValue] = useState();
  useEffect(() => {
    setValue(dayjs(GetDate() + "T" + hora));
  }, [hora]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-MX">
      <TimePicker
      error={error}
        disabled={disabledd}
        fullWidth={fullWidth}
        ampm={false}
        orientation="landscape"
        label={label}
        value={Value}
        helperText={helpertext}
        inputFormat={format}
        onChange={setTime}
        
        renderInput={(params) => (
          <TextField
            
            {...params}
            size={size}
            inputProps={{ ...params.inputProps, readOnly: true }}
            fullWidth={fullWidth}
            helperText={helpertext} // Asegúrate de pasar helperText aquí
          />
        )}
      />
    </LocalizationProvider>
  );
};